import partImg from './../assets/part.png'
import orderJS from '@/api/orderapi'
import { MessageBox } from 'element-ui'

export default {
  name: 'orderdetail',
  components: {
  },

  data() {
    return {
      language: window.localStorage.getItem('lang') || 'cn',
      id: null,
      partImg,
      name: 'Maria refuted',
      address: '15A2221, 3rd Floor, Yifang City, Baoan District, Shenzhen, Guangdong Province',
      postalcode: '342356',
      country: 'America',
      province: 'NewYork',
      phone: '19926455781',
      email: '235346@qq.com',

      ordernum: '42656457769-455',
      carrier: 'Fedex',
      status: 'shipping',
      trackid: '1231231231312',

      orderData: [
        { icon: partImg, code: 'Set Code:  M46457-435', id: 'Part ID:  45274-74', color: 'Color:  Metallic Gold', num: 10 },
        { icon: partImg, code: 'Set Code:  M46457-435', id: 'Part ID:  45274-74', color: 'Color:  Metallic Gold', num: 10 },
        { icon: partImg, code: 'Set Code:  M46457-435', id: 'Part ID:  45274-74', color: 'Color:  Metallic Gold', num: 10 },
        { icon: partImg, code: 'Set Code:  M46457-435', id: 'Part ID:  45274-74', color: 'Color:  Metallic Gold', num: 10 },
        { icon: partImg, code: 'Set Code:  M46457-435', id: 'Part ID:  45274-74', color: 'Color:  Metallic Gold', num: 10 },
        { icon: partImg, code: 'Set Code:  M46457-435', id: 'Part ID:  45274-74', color: 'Color:  Metallic Gold', num: 10 },
        { icon: partImg, code: 'Set Code:  M46457-435', id: 'Part ID:  45274-74', color: 'Color:  Metallic Gold', num: 10 },
        { icon: partImg, code: 'Set Code:  M46457-435', id: 'Part ID:  45274-74', color: 'Color:  Metallic Gold', num: 10 },
        { icon: partImg, code: 'Set Code:  M46457-435', id: 'Part ID:  45274-74', color: 'Color:  Metallic Gold', num: 10 },
        { icon: partImg, code: 'Set Code:  M46457-435', id: 'Part ID:  45274-74', color: 'Color:  Metallic Gold', num: 10 }
      ]
    }
  },
  created() {
    console.log('[page] order param.', this.$route.query);
    var that = this;
    var id = this.$route.query.id;
    if (!id) {
      MessageBox.alert(
        'Order not existed',
        'Notification',
        {
          confirmButtonText: this.$t('buttons.close'),
          type: 'warning'
        }
      ).then(() => {
        this.$router.replace({ path: '/' });
        location.reload();
      });
      return;
    }
    this.id = id;
    orderJS.orderGet(this.id).then(res => {
      var orderData = [];
      res.data.order.parts.forEach(function (e) {
        orderData.push({
          icon: e.icon,
          code: res.data.order.orderinfo.targetid,
          id: e.part_sku_id,
          color: that.language == 'cn' ? e.colorname : e.encolorname,
          num: e.qty,
          max: res.data.parts.filter(item => {
            if (item.part_sku_id == e.part_sku_id)
              return item;
          })[0].qty,
        });
      });

      this.orderData = orderData;
      this.name = res.data.order.address.username;
      this.address = res.data.order.address.address;
      this.postalcode = res.data.order.address.postcode;
      this.country = res.data.order.address.nationname;
      this.province = res.data.order.address.provincename;
      this.phone = res.data.order.address.cellphone;
      this.email = res.data.order.address.email;
      this.ordernum = res.data.order.orderinfo.orderserialno;


      if (res.data.order.orderinfo.status == 202) {
        this.carrier = '';
        this.status = this.$t('orderdetail.express_cancel');
        this.trackid = '';
      } else if (!res.data.order.expressinfo || !res.data.order.expressinfo.postid) {
        this.carrier = '';
        this.status = this.$t('orderdetail.express_processing');
        this.trackid = '';
      } else {
        this.carrier = res.data.order.expressinfo.type;
        this.status = this.$t('orderdetail.express_shipped');
        this.trackid = res.data.order.expressinfo.postid;
      }


    }).catch(err => {
      console.log(err);
      MessageBox.alert(
        this.$t('error.' + err.data),
        this.$t('notification'),
        {
          confirmButtonText: 'BACK',
          type: 'warning'
        }
      ).then(() => {
        this.$router.replace('/');
        location.reload();
      })
    });
  },
  computed: {
    totalType: function () {
      let arr = this.orderData.filter(item => item.num)
      return arr.length
    },
    totalParts: function () {
      let num = 0
      this.orderData.map(item => num += item.num)
      return num
    },
  },
  methods: {

  }
}