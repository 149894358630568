import orderJS from '@/api/orderapi'
import { MessageBox } from 'element-ui'
import yzm from './../assets/yzm.png'
import authJS from '@/api/authapi'
import { setToken } from '@/utils/auth'

export default {
    name: "replacementquery",
    components: {},
    data() {
        return {
            captchaT: new Date().getTime(),
            yzm,
            formData: {
                input: '',
                search: '',
                email: '',
            },
            rules: {
                search: [
                    {
                        required: true,
                        message: this.$t('rules.serialno'),
                        trigger: "blur",
                    },
                ],
                email: [
                    {
                        required: true,
                        message: this.$t('rules.email'),
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    created() {
        this.reloadCaptcha();
        var email = this.$route.query.email;
        var orderno = this.$route.query.orderno;
        if (email && orderno) {
            this.formData.search = orderno;
            this.formData.email = email;
            this.formData.emaildisable = 'disable';
            this.formData.searchdisable = 'disable';
        }
    },
    methods: {
        reloadCaptcha() {
            this.captchaT = new Date().getTime() + "." + Math.random();
            this.formData.input = '';
            this.yzm = authJS.captchaGet(this.captchaT);
        },
        reload() {
            this.reloadCaptcha();
            this.$message({
                message: 'reload verification',
                type: 'success'
            });
        },
        Searchorder() {
            this.$refs["searchForm"].validate((valid) => {
                if (!valid) {
                    console.log("error submit!!");
                    return false;
                }
                authJS.captchaCheckonce(this.captchaT, this.formData.input).then(res => {
                    setToken(res.data);
                    orderJS.orderSearch(this.formData.email, this.formData.search).then(res => {
                        this.$router.push({
                            name: 'orderdetail',
                            query: { id: res.data.id }
                        });
                    }).catch(err => {
                        MessageBox.alert(
                            this.$t('error.' + err.data),
                            this.$t('notification'),
                            {
                                confirmButtonText: this.$t('buttons.close'),
                                type: 'warning'
                            }
                        ).then(() => {
                            this.reloadCaptcha();
                        });
                    });
                }).catch(err => {
                    console.error(err);
                    MessageBox.alert(
                        this.$t('rules.captchaerror'),
                        this.$t('notification'),
                        {
                            confirmButtonText: this.$t('buttons.close'),
                            type: 'warning'
                        }
                    ).then(() => {
                        this.reloadCaptcha();
                    });
                });
            });
        },
    },
};