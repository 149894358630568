import request from '@/utils/request'

const authJS = {};

authJS.captchaGet = function(t) {
    return process.env.VUE_APP_BASE_API + '/auth/captcha/get?t=' + t;
}

authJS.captchaCheckonce = function(t, captcha) {
    return request({
        url: '/auth/captcha/checkonce',
        method : 'get',
        params : {
            t : t,
            captcha : captcha,
        }
    });
}
 
export default authJS;