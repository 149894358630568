
import request from '@/utils/request'

const mocJS = {};

mocJS.mocSearch = function (skuid, orderno) {
    skuid = skuid.replace('_', '-').replace('—', '-').replace('－','-').replace('--', '-'); 
    return request({
        url: '/client/moc/search',
        method: 'post',
        params: {
            skuid: skuid,
            orderno: orderno,
        }
    });
}

mocJS.partSearch = function (orderno) {
    return request({
        url: '/client/part/search',
        method: 'post',
        params: { 
            orderno: orderno,
        }
    });
}

export default mocJS;