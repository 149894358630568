import request from '@/utils/request'


const orderJS = {};

orderJS.orderSearch = function (email, key) {
    return request({
        url: '/client/order/search',
        method: 'get',
        params: {
            email: email,
            key: key
        }
    });
}

orderJS.orderGet = function (id) {
    return request({
        url: '/client/order/get',
        method: 'get',
        params: {
            id: id
        }
    });
}

orderJS.orderCheckout = function (vo) { 
    return request({
        url: '/client/order/checkout',
        method: 'post',
        data: vo
    });
}

export default orderJS;