import yzm from './../assets/yzm.png'
import mocJS from '@/api/mocapi'
import authJS from '@/api/authapi'
import { MessageBox } from 'element-ui'
import { setToken } from '@/utils/auth'
import md5 from 'js-md5';

export default {
    name: 'suitquery',
    components: {
    },
    data() {
        return {
            captchaT: new Date().getTime(),
            yzm,
            formData: {
                input: '',
                skuid: '',
                orderno: ''
            },
            rules: {
                orderno: [
                    { required: true, message: this.$t('rules.orderno'), trigger: 'blur' }
                ],
                skuid: [
                    { required: true, message: this.$t('rules.skuid'), trigger: 'blur' }
                ]
            }
        }
    },
    created() {
        this.reloadCaptcha();
    },
    methods: {
        reloadCaptcha() {
            this.captchaT = new Date().getTime() + "." + Math.random();
            this.formData.input = '';
            this.yzm = authJS.captchaGet(this.captchaT);
        },
        reload() {
            this.reloadCaptcha();
            this.$message({
                message: this.$t('rules.reloadcaptcha'),
                type: 'success'
            });
        },
        Searchsuit() {
            this.$refs['searchForm'].validate((valid) => {
                if (!valid) {
                    console.log('error submit!!');
                    return false;
                }
                authJS.captchaCheckonce(this.captchaT, this.formData.input).then(res => {
                    setToken(res.data);
                    mocJS.mocSearch(this.formData.skuid, this.formData.orderno).
                        then(res => {
                            res;
                            var sign = md5(this.formData.skuid + this.formData.orderno + this.captchaT);
                            sessionStorage['__BUILDMOC_SET__' + sign] = JSON.stringify(res.data);
                            this.$router.push({
                                name: 'list',
                                query: {
                                    skuid: this.formData.skuid,
                                    orderno: this.formData.orderno,
                                    sign: sign,
                                }
                            });
                        }).catch(err => {
                            MessageBox.alert(
                                this.$t('error.' + err.data),
                                this.$t('notification'),
                                {
                                    confirmButtonText: this.$t('buttons.close'),
                                    type: 'warning'
                                }
                            ).then(() => {
                                this.reloadCaptcha();
                            });
                        });
                }).catch(err => {
                    console.error(err);
                    MessageBox.alert(
                        this.$t('rules.captchaerror'),
                        this.$t('notification'),
                        {
                            confirmButtonText: this.$t('buttons.close'),
                            type: 'warning'
                        }
                    ).then(() => {
                        this.reloadCaptcha();
                    });
                });
            });
        }
    }
}