<template>
  <div id="app">
    <Header/>

    <!-- <div id="nav"> -->
      <!-- <router-link to="/">Home</router-link> |
      <router-link to="/order">order</router-link> -->
    <!-- </div> -->
    <router-view class="container"/>
    <Footer/>

  </div>
</template>
<script>
import Header from './components/header.vue'
import Footer from './components/footer.vue'
export default {
  components:{
    Header, Footer
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.container{
  padding-top: 80px;
}
</style>
