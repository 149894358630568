import partImg from './../assets/part.png'
import orderJS from '@/api/orderapi'
import countryJS from '@/local/country'
import { Notification, MessageBox } from 'element-ui'

export default {
    name: 'order',
    components: {
    },
    data() {
        return {
            language: window.localStorage.getItem('lang') || 'cn',

            sign: null,
            ordervo: null,

            addVisable: false,
            addressData: {
                country: '',
                name: '',
                address: '',
                city: '',
                province: '',
                postal: '',
                phone: '',
                email: '',
            },
            countryOption: ['country1', 'country2', 'country3'],
            cityOption: ['city1', 'city2', 'city3',],
            provinceOption: ['province1', 'province2', 'province3',],
            orderData: [
                { icon: partImg, code: 'Set Code:  M46457-435', id: 'Part ID:  45274-74', color: 'Color:  Metallic Gold', number: '1' },
                { icon: partImg, code: 'Set Code:  M46457-435', id: 'Part ID:  45274-74', color: 'Color:  Metallic Gold', number: '1' },
            ],
            rules: {
                country: [
                    { required: true, message: this.$t('order.selectTips', { name: this.$t('order.country') }), trigger: 'blur' },
                ],
                name: [
                    { required: true, message: this.$t('order.inputTips', { name: this.$t('order.fullName') }), trigger: 'blur' }
                ],
                address: [
                    { required: true, message: this.$t('order.inputTips', { name: this.$t('order.address') }), trigger: 'blur' }
                ],
                city: [
                    { required: true, message: this.$t('order.inputTips', { name: this.$t('order.city') }), trigger: 'blur' }
                ],
                province: [
                    { required: true, message: this.$t('order.inputTips', { name: this.$t('order.province') }), trigger: 'blur' }
                ],
                postal: [
                    { required: false, message: this.$t('order.inputTips', { name: this.$t('order.postal') }), trigger: 'blur' }
                    , {
                        pattern: /^[a-zA-Z0-9\\-]+$/g,
                        message: this.$t('order.postalErrorTip'),
                        trigger: "blur"
                    }
                ],
                phone: [
                    { required: true, message: this.$t('order.inputTips', { name: this.$t('order.phone') }), trigger: 'blur' }
                    , {
                        pattern: /^[0-9]+$/g,
                        message: this.$t('order.phoneErrorTip'),
                        trigger: "blur"
                    }
                ],
                email: [
                    { required: true, message: this.$t('order.inputTips', { name: this.$t('order.email') }), trigger: 'blur' }
                    , {
                        pattern: /^([0-9A-Za-z\-_.]+)@([0-9a-z]+\.[a-z]{2,3}(\.[a-z]{2})?)$/g,
                        message: this.$t('order.emailErrorTip'),
                        trigger: "blur"
                    }]
            }
        }
    },
    created() {
        console.log('[page] order param.', this.language, this.$route.query);
        var sign = this.$route.query.sign;
        if (!sign) {
            MessageBox.alert(
                this.$t('error.-3'),
                this.$t('notification'),
                {
                    confirmButtonText: this.$t('buttons.close'),
                    type: 'warning'
                }
            ).then(() => {
                this.$router.replace({ path: '/' });
                location.reload();
            });
            return;
        }
        this.sign = sign;
        this.ordervo = sessionStorage['__BUILDMOC_ORDER__' + this.sign];
        if (!this.ordervo) {
            alert(this.$t('rules.paramserror'));
            this.$router.replace({ path: '/' });
            location.reload();
            return;
        }
        this.ordervo = JSON.parse(this.ordervo);
        console.log(this.sign, this.ordervo); 
        this.updateView();
    },
    computed: {
        totalType: function () {
            let arr = this.orderData.filter(item => item.number)
            return arr.length
        },
        totalParts: function () {
            let num = 0
            this.orderData.map(item => num += item.number)
            return num
        },
    },
    methods: {
        updateView() {
            var that = this;
            this.countryOption = this.language == 'cn' ? countryJS.chcountry : countryJS.country;


            var orderData = [];
            this.ordervo.parts.forEach(function (e) {
                orderData.push({
                    icon: e.icon, 
                    id: e.part_sku_id,
                    designcode: e.designcode,
                    color: that.language == 'cn' ? e.colorname : e.encolorname,
                    number: e.number,
                    max: e.qty,
                });
            });
            this.orderData = orderData;

        },
        addLine() {
            this.addVisable = true
            // this.$refs.addInput.focus()
        },
        deletePart(e) {
            if (this.orderData.length <= 1) {
                Notification.error({
                    title: this.$t('order.noparts')
                });
                return false;
            }
            var id = e.currentTarget.dataset.id;
            MessageBox.confirm(
                this.$t('rules.deletepart'),
                this.$t('notification'),
                {
                    confirmButtonText: this.$t('buttons.yes'),
                    cancelButtonText: this.$t('buttons.cancel'),
                    type: 'warning'
                }
            ).then(() => {
                var part = this.orderData.filter(item => { return item.id == id; })[0];
                this.orderData.splice(this.orderData.indexOf(part), 1);
            });
        },
        confirmOrder() {
            // 数据校验 
            this.$refs.addressBar.validate((valid, obj) => {
                if (!valid) {
                    var offset = 0;
                    Object.keys(obj).forEach(function (k) {
                        Notification.error({
                            title: obj[k][0].message,
                            offset: offset += 30
                        });
                    });
                    return false;
                }
                var nationcode = this.countryOption.filter(item => { return item.name == this.addressData.country; })[0].code;
                if (nationcode != '47') {
                    if (!this.addressData.postal || this.addressData.postal == '') {
                        Notification.error({
                            title: this.$t('order.postalErrorTip'),
                            offset: offset += 30
                        });
                        return false;
                    }
                }
                if (this.ordervo.maxpartcount > 0 && this.totalParts > this.ordervo.maxpartcount) {
                    Notification.error({
                        title: this.$t('error.-104').replace("#COUNT", this.ordervo.maxpartcount)
                    });
                    return;
                }
                var vo = {};
                vo.orderinfo = {
                    targetid: this.sign,
                    ordertype: this.ordervo.orderinfo.ordertype,
                    orderno: this.sign,
                    orderserialno: this.sign,
                };
                console.log(vo, this.ordervo);
                var address = this.addressData.address;
                if (this.addressData.address2)
                    address = this.addressData.address2 + ' ' + address;
                vo.address = {
                    username: this.addressData.name,
                    cellphone: this.addressData.phone,
                    languagecode: this.language,
                    nationcode: nationcode,
                    nationname: this.addressData.country,
                    countyName: undefined,
                    provincename: this.addressData.province,
                    cityname: this.addressData.city,
                    address: address,
                    postcode: this.addressData.postal,
                    email: this.addressData.email
                };
                vo.parts = [];
                this.orderData.map(item => {
                    if (item.number > 0)
                        vo.parts.push({
                            part_sku_id: item.id,
                            qty: item.number
                        });
                })
                if (vo.parts.length <= 0) {
                    Notification.error({
                        title: this.$t('order.noparts')
                    });
                    return;
                }
                MessageBox.confirm( 
                    this.$t('order.confirmbox'),
                    this.$t('notification'),
                    {
                        confirmButtonText: this.$t('order.ok'),
                        cancelButtonText: this.$t('order.cancel2'),
                        type: 'warning'
                    }
                ).then(() => {
                    MessageBox.alert('这里需要接入微信支付', '接入说明');
                });
            });
        },
    }

}