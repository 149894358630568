import moc from './../assets/MOC Set Order.png'
import parts from './../assets/parts1.png'
 

export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      moc,
      parts,
      list:[]
    }
  },
  methods:{ 
		toSuitquery(){ 
			this.$router.push('/suitquery')
		},
		toPartsquery(){
			this.$router.push('/partsquery')
		}
	}
}