import Cookies from 'js-cookie'

const TokenKey = '__CAPTCHA_SESSION__'

export function getToken() {
  var token = sessionStorage[TokenKey];
  if (!token)
    token = localStorage[TokenKey];
  if (!token)
    token = Cookies.get(TokenKey);
  return token;
}

export function setToken(token) {
  console.log('[TOKEN] set token.', token);
  sessionStorage[TokenKey] = token;
  localStorage[TokenKey] = token;
  return Cookies.set(TokenKey, token)
}

export function removeToken() { 
  var token = Cookies.remove(TokenKey);
  console.log('[TOKEN] remove token.', token);
  return token;
}
