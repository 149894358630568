import { render, staticRenderFns } from "./replacementquery.vue?vue&type=template&id=723ada97&scoped=true&"
import script from "@/service/replacementqueryservice.js?vue&type=script&charset=utf-8&lang=js&"
export * from "@/service/replacementqueryservice.js?vue&type=script&charset=utf-8&lang=js&"
import style0 from "./replacementquery.vue?vue&type=style&index=0&id=723ada97&scoped=true&lang=scss&"
import style1 from "./replacementquery.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "723ada97",
  null
  
)

export default component.exports