
const toolJS = {};

toolJS.getfontColor = function (backgroundColor) {
    if (this.colorDiff(backgroundColor, '000000') < this.colorDiff(backgroundColor, 'ffffff')) {
        return 'ffffff';
    }
    return '000000';
};

toolJS.colorDiff = function (c1, c2) {
    var c11 = parseInt(c1.substring(0, 2), 16);
    var c12 = parseInt(c1.substring(2, 4), 16);
    var c13 = parseInt(c1.substring(4, 6), 16);
    var c21 = parseInt(c2.substring(0, 2), 16);
    var c22 = parseInt(c2.substring(2, 4), 16);
    var c23 = parseInt(c2.substring(4, 6), 16);
    return Math.abs(c11 - c21) + Math.abs(c12 - c22) + Math.abs(c13 - c23);
};

toolJS.arraySearch = function (array, prop, value) {
    for (var element in array) {
        element = array[element];
        if (element[prop] == value)
            return element;
    }
    return null;
};

toolJS.arraySearchs = (array, conditions) => {
    var values = [];
    array.forEach(function (element) {
        var founded = true;
        Object.keys(conditions).forEach(function (key) {
            if (element[key] != conditions[key])
                founded = false;
        });
        if (founded)
            values.push(element);
    });
    return values;
};

export default toolJS;