import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import { getToken, removeToken } from '@/utils/auth'
import router from '@/router'
import Vue from 'vue'
import ElementUI from 'element-ui'

Vue.use(ElementUI);

// https://www.cnblogs.com/Gherardo/p/12005099.html
let loading;

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 30000
})
// request拦截器
service.interceptors.request.use(config => {
  let lang = window.localStorage.getItem('lang');
  loading = Vue.prototype.$loading({
    lock: true,
    text: lang == 'cn' ? '加载中 请稍候' : 'Loading',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)'
  });
  console.log('[API] req:', config.url, config.params);
  if (getToken()) {
    config.headers['__CAPTCHA_SESSION__'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  return config
},
  error => {
    loading.close();
    console.log(error)
    Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(res => {
  loading.close();
  console.log('[API] resp:', res.data);
  const code = res.data.code
  if (code === 401) {
    var title = 'Login is expired, redirect to index.html.';
    var notification = 'Notification';
    let lang = window.localStorage.getItem('lang');
    if (lang == 'cn') {
      title = '登录超时，返回首页';
      notification = '通知';
    }
    MessageBox.alert(
      title, notification,
      {
        confirmButtonText: 'OK',
        type: 'warning'
      }
    ).then(() => {
      removeToken();
      router.replace({ path: '/' });
      location.reload();
    });
    return Promise.reject(res.data);
  } else if (code != 0) {
    if (!res.data)
      res.data = {};
    if (!res.data.data)
      res.data.data = 'server-error';
    return Promise.reject(res.data);
  } else {
    return res.data
  }
},
  error => {
    loading.close();
    console.log('err' + error)
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    if (!error.data)
      error.data = 'server-error';
    return Promise.reject(error)
  }
)

export default service
