import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueI18n from 'vue-i18n'

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(VueI18n)

const i18n = new VueI18n({  
  locale: localStorage.getItem('lang') || 'cn', //缓存获取用户中英文选择，没有则默认中文 
  formatFallbackMessages: true,  
  messages: {   
    'cn': require('./local/cn'),   // 中文语言包   
    'en': require('./local/en')    // 英文语言包  
  }}
)

new Vue({
  i18n,
  router,
  render: function (h) { return h(App) }
}).$mount('#app')
