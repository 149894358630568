module.exports = {
	language: {
		chinese: '中文',
		english: 'English'
	},
	menu: {
		home: "home",
		replacement: 'Replacement Order'
	},
	order: {
		orderTitle: 'Your replacement order',
		shipTo: 'Ship To',
		country: 'Country',
		countryDesc: 'International postal addresses allowed',
		fullName: 'Full name',
		address: 'Street address',
		addressTip: 'Street address, PO. box, company name, c/o',
		city: 'City',
		province: 'Province',
		postal: 'ZIP / Postal code',
		postalErrorTip: 'ZIP / Postal code format error',
		phone: 'Phone number',
		phoneErrorTip: 'Phone number format error',
		email: 'Email address',
		emailErrorTip: 'Email address format error',
		emailTip: 'Order and logistics information will be notified via your email, please fill',
		confirmOrder: 'Confirm order',
		totalText: 'Total: {type} types,{parts} parts',
		deleteMessage: 'You are about to empty your bag! Are you sure?',
		ok: 'Ok Continue',
		cancel: 'No Thanks',
		cancel2: 'No Thanks',
		addLine: '+ Add a line',
		floorTips: 'Apartment, suite, unit, building, floor, etc.',
		selectTips: 'please select {name}',
		inputTips: 'please input {name}',
		noparts: 'Order cannot empty',
		designcode: 'Design Code',
		color: 'Color',
		confirmbox: 'This order only allows one free replacement, please check the list carefully. Are you sure?',
	},
	parts: {
		orderNumber: 'Order No.',
		cbrkcksQty: 'Brick QTY',
		brickList: 'Bricks List',
		brickCount: 'Bricks available for that set',
		emptyBag: 'Empty Bag',
		searchPart: 'Enter LEGO Design Code',
		deleteMessage: 'You are about to empty your bag! Are you sure?',
		ok: 'Ok Continue',
		cancel: 'No Thanks',
		noparts: 'Order has no part',
		designcode: 'Design Code',
		color: 'Color',
		size: 'Size',
		name : 'Name',
		desc: 'Please Click Image',
	},
	home: {
		mocSet: 'Set Order',
		brick: 'Brick Order'
	},
	orderdetail: {
		orderTitle: 'Your replacement order',
		expressTitle: 'Order Summary',
		addressTitle: 'Ship To',
		name: 'Name',
		address: 'Address',
		postalCode: 'Postal Code',
		country: 'Country',
		province: 'province',
		phone: 'Phone number',
		email: 'Email',
		orderNum: 'Order Number',
		carrier: 'Carrier',
		status: 'Order Status',
		id: 'Tracking ID',
		hintText: 'Order and logistics information will be notified via your email, please check.',
		express_shipped: 'Shipped',
		express_processing: 'Processing',
		express_cancel: 'Cancelled',
		setcode: 'Set Code',
		designcode: 'Design Code',
		color: 'Color',
	},
	suitquery: {
		title: 'Verify your Set order',
		button_reload: 'Reload',
	},
	partsquery: {
		title: 'Verify your Brick order',
		button_reload: 'Reload',
	},
	replacementquery: {
		title: 'Check replacement order status',
		button_reload: 'Reload',
	},
	error: {
		'server-error': 'Server Exception, please try later',
		'-1': 'Set is not existed! Please contact Seller.',
		'-2': 'Set is offline, cannot process!',
		'-3': 'Request Internal Params ERROR!',
		'-4': 'Order not existed! Please contact Seller.',
		'-5': 'Order already existed!',

		'-101': 'Already create same order!',
		'-102': 'Sales order NOT existed!',
		'-103': 'Replacement order reach MAX!',
		'-104': 'The maximun replacement part is: #COUNT',
		'-105': 'Replacement order part greater than Set part count!',
		'-106': 'Set part not exist!',
		'-999': 'Replacement Order ERROR!',
	},
	notification: 'Notification',
	rules: {
		areyousure: 'Are you sure?',
		reloadcaptcha: 'Reload captcha',
		loginexpire: 'Login is expired, redirect to index.html.',
		orderno: 'Enter your order number',
		skuid: 'Enter the C****-**** on the package',
		captcha: 'Enter verify code',
		captchaerror: 'Captcha is error, please retry.',
		paramserror: 'Page parameters error!',
		serialno: 'Enter your order number',
		email: 'Enter your email of the Replacement Order',
		deletepart: 'Are you sure to delete this part?',
	},
	buttons: {
		select: 'Select',
		yes: 'Yes',
		cancel: 'Cancel',
		close: 'Close',
	}
}