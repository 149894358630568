import img from './../assets/parts_default.png'
import partImg from './../assets/part.png'
import toolJS from '@/utils/tool'
import { Notification, MessageBox } from 'element-ui'

export default {
    name: 'list',
    components: {
    },

    data() {
        return {
            language: window.localStorage.getItem('lang') || 'cn',
            searchOptions: [{ labelCn: "红色", labelEn: "red", value: "选项1", bg: "#ff0000", fg: "#ffffff" }, { labelCn: "黄色", labelEn: "yellow", bg: "#fff000", value: "选项2", fg: "#333333" }],           // 选择搜索 类型
            select: "",
            img,
            partImg,

            skuid: null,
            orderno: null,
            orderSign: null,
            ordervo: null,

            selectedpartid: null,
            selectedpartcolor: null,

            partInfo: {
                name: 'C7345',
                text: 'Constitution Class U.S.S. Enterprise',
                desc: 'Bricks available for that set: 206'
            },
            searchData: {
                search: "",
            },
            rules: {
                search: [
                    {
                        required: false,
                        message:
                            this.$t('parts.searchPart'),
                        trigger: "blur",
                    },
                ],
            },
            showPartData: [],
            partData: [
                {
                    text: 'Part ID: 56274-7434 (Metallic Gold)',
                    number: 0
                },
                {
                    text: 'Part ID: 56274-7434 (Metallic Gold)dfgdfgdfgdfg',
                    number: 0
                },
                {
                    text: 'Part ID: 56274-7434 (Metallic Gold)',
                    number: 0
                },
                {
                    text: 'Part ID: 56274-7434 (Metallic Gold)',
                    number: 0
                },
            ]
        }
    },
    created() {
        console.log('[page] list param.', this.$route.query);
        var skuid = this.$route.query.skuid;
        var orderno = this.$route.query.orderno;
        var sign = this.$route.query.sign;
        if (!sign || !skuid || !orderno) {
            alert(this.$t('rules.paramserror'));
            this.$router.replace({ path: '/' });
            location.reload();
            return;
        }
        this.skuid = skuid;
        this.orderno = orderno;
        this.orderSign = sign;
        this.ordervo = (sessionStorage['__BUILDMOC_SET__' + this.orderSign]);
        if (!this.ordervo || this.ordervo == null) {
            MessageBox.alert(
                this.$t('rules.paramserror'),
                this.$t('notification'),
                {
                    confirmButtonText: this.$t('buttons.close'),
                    type: 'warning'
                }
            ).then(() => {
                this.$router.replace({ path: '/' });
                location.reload();
            });
            return;
        }
        this.ordervo = JSON.parse(this.ordervo);
        console.log(this.ordervo);
        this.updateView();
    },
    computed: {
        totalType: function () {
            let arr = this.partData.filter(item => item.number)
            return arr.length
        },
        totalParts: function () {
            let num = 0
            this.partData.map(item => num += item.number)
            return num
        },
        setParts: function () {
            let num = 0;
            this.partData.map(item => num += item.qty)
            return num;
        }
    },
    methods: {
        updateView() {
            var that = this;
            // update partdata
            this.partInfo.name = this.ordervo.set.skuid;
            this.partInfo.text = this.language == 'cn' ? this.ordervo.set.name : this.ordervo.set.enname;
            this.partInfo.desc = this.ordervo.set.description;
            var partData = [];
            this.ordervo.parts.forEach(function (part) {
                if (!part.size)
                    part.size = 'N/A';
                partData.push({
                    part_sku_id: part.part_sku_id,
                    designcode: part.designcode,
                    colorid: part.colorid,
                    colorname: that.language == 'cn' ? part.colorname : part.encolorname,
                    number: 0,
                    qty: part.qty,
                    icon: part.icon,
                    size: that.language == 'cn' ? part.size : part.ensize,
                });
            });
            this.partData = partData;
            this.showPartData = partData;

            // init color droplist
            var searchOptions = [{
                labelCn: "全部", labelEn: "All", value: "-1", bg: "#ffffff", fg: "#000000"
            }];
            this.ordervo.partcolors.forEach(function (item) {
                searchOptions.push({
                    labelCn: item.chcolorname,
                    labelEn: item.colorname,
                    value: item.colorid,
                    bg: "#" + item.colorhtml,
                    fg: '#' + toolJS.getfontColor(item.colorhtml)
                });
            });
            this.searchOptions = searchOptions;

            // update by sessionStorage
            var ordervo = sessionStorage['__BUILDMOC_ORDER__' + this.orderSign];
            if (ordervo) {
                ordervo = JSON.parse(ordervo);
                if (ordervo.parts) {
                    ordervo.parts.forEach(function (part) {
                        var partDataItem = that.partData.filter(i => i.part_sku_id == part.part_sku_id);
                        console.log(partDataItem);
                        if (!partDataItem || partDataItem.length <= 0)
                            return;
                        partDataItem[0].number = part.number;
                    });
                }
            }
        },
        refreshView() {
            var showPartData = [];
            var that = this;
            this.partData.forEach(function (item) {
                if (that.selectedpartid == null || that.selectedpartid == '') {
                    showPartData.push(item);
                    return;
                }
                if (item.designcode.indexOf(that.selectedpartid) >= 0) {
                    showPartData.push(item);
                    return;
                }
                if (item.part_sku_id.indexOf(that.selectedpartid) >= 0) {
                    showPartData.push(item);
                    return;
                }
            });
            var showPartData2 = [];
            if (this.selectedpartcolor != null && this.selectedpartcolor >= 0) {
                showPartData.forEach(function (item) {
                    if (item.colorid == that.selectedpartcolor)
                        showPartData2.push(item);
                });
            }
            else {
                showPartData2 = showPartData;
            }
            this.showPartData = showPartData2;
        },


        onSearchFocus() {
            this.searchData.search = '';
            this.selectedpartid = null;
        },

        Searchpart() {
            var partid = this.searchData.search;
            this.selectedpartid = partid;
            this.refreshView();

        },
        Selectcolor() {
            this.selectedpartcolor = this.select;
            this.refreshView();
        },
        toOrder() {
            var that = this;
            var ordervo = {};
            this.$refs["searchForm"].validate((valid) => {
                if (!valid) {
                    console.log("error submit!!");
                    return false;
                }
                ordervo.maxpartcount = that.ordervo.maxpartcount;
                ordervo.orderinfo = {
                    orderno: this.orderno,
                    targetid: this.skuid,
                    ordertype: 1,
                };
                ordervo.parts = [];
                this.partData.map(item => {
                    if (item.number > 0) {
                        var ordervopart = that.ordervo.parts.filter(i => i.part_sku_id == item.part_sku_id)[0];
                        ordervo.parts.push({
                            icon: ordervopart.icon,
                            part_sku_id: ordervopart.part_sku_id,
                            designcode: ordervopart.designcode,
                            colorid: ordervopart.colorid,
                            colorname: ordervopart.colorname,
                            encolorname: ordervopart.encolorname,
                            number: item.number,
                            qty: item.qty,
                            size: item.size,
                        });
                    }
                })
                if (ordervo.parts.length <= 0) {
                    Notification.error({
                        title: this.$t('parts.noparts')
                    });
                    return;
                }
                if (this.ordervo.maxpartcount > 0 && this.totalParts > this.ordervo.maxpartcount) {
                    Notification.error({
                        title: this.$t('error.-104').replace("#COUNT", this.ordervo.maxpartcount)
                    });
                    return;
                }

                sessionStorage['__BUILDMOC_ORDER__' + this.orderSign] = JSON.stringify(ordervo);
                var router = { name: 'order', query: { sign: this.orderSign } };
                console.log(router);
                this.$router.push(router);
            });
        },
        emptyBag() {
            this.$confirm(this.$t('parts.deleteMessage'), '', {
                confirmButtonText: this.$t('parts.ok'),
                cancelButtonText: this.$t('parts.cancel'),
                type: 'warning'
            }).then(() => {
                this.partData.map(item => {
                    item.number = 0
                })
            })
        }
    }
}