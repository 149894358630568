<template>
	<footer>
	</footer>
</template>

<script>
export default {
  name: 'Footer',
  props: {
  }
}
</script>
<style scoped lang="scss">
footer{
  width: 100%;
  height: 320px;
  background: blue;
  color: #FFF;
  font-size:24px;
  background: url('../assets/weibu.png') repeat-x left top ;
  background-size: auto 100% ;
  z-index: 99;
}

@media screen and (max-width: 768px) {
	footer {
    height: 200px;
  }
}

</style>
