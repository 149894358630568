import { render, staticRenderFns } from "./list.vue?vue&type=template&id=b2113aa0&scoped=true&"
import script from "@/service/listservice.js?vue&type=script&charset=utf-8&lang=js&"
export * from "@/service/listservice.js?vue&type=script&charset=utf-8&lang=js&"
import style0 from "./list.vue?vue&type=style&index=0&lang=css&"
import style1 from "./list.vue?vue&type=style&index=1&id=b2113aa0&scoped=true&lang=scss&"
import style2 from "./list.vue?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b2113aa0",
  null
  
)

export default component.exports