<template>
  <header>
    <div class="content">
      <div class="logo" @click="goHome">
        <img :src="logo" alt="" />
        <p>{{ $t("menu.home") }}</p>
      </div>
      <div class="info">
        <div class="replacement" @click="goHome">
          {{ $t("menu.replacement") }}
        </div>
        <div class="language">
          <button
            :class="{ active: language === 'cn' }"
            @click="changeLang('cn')"
          >
            {{ $t("language.chinese") }}
          </button>
          <button
            :class="{ active: language === 'en' }"
            @click="changeLang('en')"
          >
            {{ $t("language.english") }}
          </button>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import logo from "./../assets/logo.png";

export default {
  name: "Header",
  props: {},
  data() {
    return {
      logo,
      language: window.localStorage.getItem("lang") || "cn",
    };
  },
  created() {
    var lang = this.$route.query.lang;
    if (lang) {
      if (lang == "en" || lang == "cn") {
        this.$router.push({ query : {} });
        this.changeLang(lang);
      }
    }
  },
  methods: {
    goHome() {
      this.$router.push("/");
    },
    toReplacementquery() {
      this.$router.push("/replacementquery");
    },
    toRoot() {
      window.location.href = "./";
    },
    changeLang(lang) {
      console.log(lang);
      if (lang !== this.language) {
        this.language = lang;
        window.localStorage.setItem("lang", lang);
      }
      window.location.reload();
    },
  },
};
</script>
<style scoped lang="scss">
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background: #ffffff;
  box-shadow: 0px 0px 5px 1px rgba(29, 29, 39, 0.26);
  z-index: 99;

  .content {
    display: flex;
    max-width: 1240px;
    width: 100%;
    height: 80px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    .logo {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding-left: 5px;
      img {
        width: 48px;
        height: 48px;
      }
      p {
        margin-left: 40px;
        padding-left: 32px;
        background: url("../assets/home.png") no-repeat left center;
        background-size: 22px;
        font-size: 18px;
        color: #1b1b1b;
        &:hover {
          color: #4587f4;
        }
      }
    }
    .info {
      display: flex;
      height: 80px;
      justify-content: space-between;
      align-items: center;
      padding-right: 10px;

      .replacement {
        height: 80px;
        line-height: 80px;
        font-size: 16px;
        font-weight: 600;
        padding-left: 32px;
        color: #1b1b1b;
        background: url("../assets/replace.png") no-repeat left center;
        cursor: pointer;
        &:hover {
          color: #4587f4;
        }
      }
      .language {
        margin-left: 20px;
        button {
          width: 86px;
          height: 40px;
          vertical-align: top;
          font-size: 16px;
          color: #fff;
          outline: none;
          border: none;
          background: #848a8f;
          cursor: pointer;
          &:first-child {
            border-radius: 20px 0 0 20px;
          }
          &:last-child {
            border-radius: 0 20px 20px 0;
          }
        }
        .active {
          background: #4587f4;
        }
      }
    }
  }
}

/* 移动端适配 */
@media screen and (max-width: 768px) {
  html {
    font-size: 50px;
  }
  header {
    height: 70px;
    .content .logo {
      img {
        width: 25px;
        height: auto;
      }
      p {
        line-height: 70px;
        margin-left: 10px;
        background-size: 16px auto;
        padding-left: 20px;
        font-size: 12px;
        font-weight: 600;
      }
    }
    .content .info {
      height: 70px;
      .replacement {
        height: 70px;
        line-height: 70px;
        font-size: 10px;
        padding-left: 20px;
        background-size: 14px auto;
      }
      .language {
        margin-left: 15px;
        text-align: center;
        button {
          width: auto;
          height: 32px;
          font-size: 12px;
          padding: 0 11px;
          margin-left: -5px;
        }
      }
    }
  }
}
</style>
