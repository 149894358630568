module.exports = {
	language: {
		chinese: '中文',
		english: 'English'
	},
	menu: {
		home: "首页",
		replacement: '补件订单'
	},
	order: {
		orderTitle: '您的补件单明细',
		shipTo: '邮寄地址',
		country: '国家',
		countryDesc: '支持国际快递物流',
		fullName: '姓名',
		address: '地址',
		addressTip: '街道地址、信箱、公司名、门牌号',
		city: '城市',
		province: '省份',
		postal: '邮政编码',
		postalErrorTip: '邮政编码格式错误',
		phone: '电话',
		phoneErrorTip: '电话格式错误',
		email: '邮箱',
		emailErrorTip: '电子邮箱格式错误',
		emailTip: '订单和物流信息将会通过邮件形式发送给您，请务必填写',
		confirmOrder: '确认订单',
		totalText: '购物车: {type} 种类型, {parts} 个零件',
		deleteMessage: 'You are about to empty your bag! Are you sure?',
		ok: '确认',
		cancel: '取消',
		cancel2: '不，再想想',
		addLine: '+ 加一行',
		floorTips: '公寓, 单元, 建筑, 楼层等.',
		selectTips: '请选择{name}',
		inputTips: '请输入{name}',
		noparts: '订单不能为空',
		designcode: '零件编码',
		color: '颜色',
		confirmbox: '该订单只允许免费补一次件，请仔细核对清单。是否确认？',
	},
	parts: {
		orderNumber: '订单编码',
		cbrkcksQty: '订单零件数',
		brickList: '套装零件清单',
		brickCount: '该套装的零件数量是',
		emptyBag: '清空购物车',
		searchPart: '输入乐高零件编码',
		deleteMessage: '您将会清空购物车! 是否确认?',
		ok: '确认继续',
		cancel: '取消',
		noparts: '购物车为空',
		designcode: '零件编码',
		color: '颜色',
		size: '尺寸',
		name : '名称',
		desc: '请点击图片',
	},
	home: {
		mocSet: '套装订单',
		brick: '零件订单'
	},
	orderdetail: {
		orderTitle: '您的补件单明细',
		expressTitle: '订单物流明细',
		addressTitle: '地址信息',
		name: '名字',
		address: '地址',
		postalCode: '邮政编码',
		country: '国家',
		province: '省份',
		phone: '电话',
		email: '邮箱',
		orderNum: '订单编号',
		carrier: '物流',
		status: '订单状态',
		id: '物流单号',
		hintText: '订单和物流信息将通过您的电子邮件通知，请查收。',
		express_shipped: '已经发货',
		express_processing: '处理中',
		express_cancel: '取消',
		setcode: '套装编码',
		designcode: '零件编码',
		color: '颜色',
	},
	suitquery: {
		title: '验证您的套装订单',
		button_reload: '刷新',
	},
	partsquery: {
		title: '验证您的零件订单',
		button_reload: '刷新',
	},
	replacementquery: {
		title: '补件单明细查询',
		button_reload: '刷新',
	},
	error: {
		'server-error': '服务端错误，请稍候尝试',
		'-1': '套装信息不存在！请联系卖家.',
		'-2': '套装已经下架，不支持补件!',
		'-3': '请求参数错误!',
		'-4': '订单不存在! 请联系卖家.',
		'-5': '订单已经存在!',

		'-101': '已经创建类似订单!',
		'-102': '订单不存在!',
		'-103': '数量已满，无法再创建补件订单',
		'-104': '该订单补件数量最多为: #COUNT个',
		'-105': '补件订单零件数量超出套装零件数量',
		'-106': '套装零件不存在',
		'-999': '订单错误',
	},
	notification: '通知',
	rules: {
		areyousure: '确认操作吗?',
		reloadcaptcha: '验证码已刷新',
		loginexpire: '登录状态过期，重新跳转首页.',
		orderno: '请输入你的订单编码',
		skuid: '请输入包装上的编码，格式为: C****-****',
		captcha: '请输入验证码',
		captchaerror: '验证码错误, 请重新输入',
		paramserror: '页面参数错误',
		serialno: '请输入你的订单编码',
		email: '请输入补件订单填写的电子邮箱',
		deletepart: '确定要删除此零件吗？',
	},
	buttons: {
		select: '选择',
		yes: '是的',
		cancel: '取消',
		close: '关闭',
	}
}