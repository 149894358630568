import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'
import Order from '../views/order.vue'
import List from '../views/list.vue'
import Parts from '../views/parts.vue'
import SuitQuery from '../views/suitquery.vue'
import PartsQuery from '../views/partsquery.vue'
import ReplacementQuery from '../views/replacementquery.vue'
import OrderDetail from '../views/orderdetail.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '',
    component: Parts
  },
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/order',
    name: 'order',
    component: Order
  },
  {
    path: '/list',
    name: 'list',
    component: List
  },
  {
    path: '/parts',
    name: 'parts',
    component: Parts
  },
  {
    path: '/replacementquery',
    name: 'replacementquery',
    component: ReplacementQuery
  },
  {
    path: '/suitquery',
    name: 'suitquery',
    component: SuitQuery
  },
  {
    path: '/partsquery',
    name: 'partsquery',
    component: PartsQuery
  },
  {
    path: '/orderdetail',
    name: 'orderdetail',
    component: OrderDetail
  }
]

const router = new VueRouter({
  routes
})

export default router
