import img from './../assets/parts1.png'
import partImg from './../assets/part.png'
import toolJS from '@/utils/tool'
import partJSON from '@/utils/part.js'
import { Notification, MessageBox } from 'element-ui'

export default {
  name: 'list',
  components: {
  },

  data() {
    return {
      language: window.localStorage.getItem('lang') || 'cn',
      searchOptions: [],// [{ labelCn: "红色", labelEn: "red", value: "选项1", bg: "#ff0000", fg: "#ffffff" }, { labelCn: "黄色", labelEn: "yellow", bg: "#fff000", value: "选项2", fg: "#333333" }],           // 选择搜索 类型

      select: "",
      img,
      partImg,

      qty: '',
      ordernum: 0,

      selectedpartid: null,
      selectedpartcolor: null,

      searchData: {
        search: "",
      },
      rules: {
        search: [
          {
            required: false,
            message:
              "Enter the last 8 digit of your parts replacement order number",
            trigger: "blur",
          },
        ],
      },
      partData: [
        {
          text: 'Part ID: 56274-7434 (Metallic Gold)',
          number: 0
        },
        {
          text: 'Part ID: 56274-7434 (Metallic Gold)dfgdfgdfgdfg',
          number: 0
        },
        {
          text: 'Part ID: 56274-7434 (Metallic Gold)',
          number: 0
        },
        {
          text: 'Part ID: 56274-7434 (Metallic Gold)',
          number: 0
        },
        {
          text: 'Part ID: 56274-7434 (Metallic Gold)',
          number: 0
        },
        {
          text: 'Part ID: 56274-7434 (Metallic Gold)',
          number: 0
        },
        {
          text: 'Part ID: 56274-7434 (Metallic Gold)',
          number: 0
        },
        {
          text: 'Part ID: 56274-7434 (Metallic Gold)',
          number: 0
        },
        {
          text: 'Part ID: 56274-7434 (Metallic Gold)',
          number: 0
        },
      ],
      showPartData: [],
    }
  },
  created() {
    console.log(partJSON);
    this.updateView();
  },
  computed: {
    totalType: function () {
      let arr = this.partData.filter(item => item.number)
      return arr.length
    },
    totalQty: function () {
      if (!this.ordervo)
        return 0;
      let num = 0
      this.ordervo.order.parts.map(item => num += item.qty)
      return num;
    },
    totalParts: function () {
      let num = 0
      this.partData.map(item => num += item.number)
      return num
    },
  },
  methods: {
    updateView() {
      var partData = [];
      partJSON.forEach(function (category) {
        Object.keys(category.parts).forEach(function (part) {
          part = category.parts[part];
          partData.push({
            part_sku_id: part.id,
            designcode: part.id,
            name: part.desc,
            number: 0,
            qty: 999,
            icon: 'http://legopart2-1251291988.file.myqcloud.com/' + part.icon,
          });
        });
      });
      this.partData = partData;
      this.updateCategoryView();
    },
    updateCategoryView() {
      var that = this;
      var showPartData = [];
      partJSON.forEach(function (category) {
        category.categories.forEach(function (part) {
          var part2 = category.parts[part.id];
          showPartData.push({
            part_sku_id: part.id,
            designcode: part.id,
            name: part.title,
            number: 0,
            qty: -1,
            icon: 'http://legopart2-1251291988.file.myqcloud.com/' + part2.icon,
          });
        });
      });
      this.showPartData = showPartData;
    },
    updatePartView() {
      if (!this.searchData.search) {
        this.updateCategoryView();
        return;
      }
      var showPartData = [];
      for (var i = 0; i < partJSON.length; i++) {
        for (var j = 0; j < partJSON[i].categories.length; j++) {
          if (this.searchData.search == partJSON[i].categories[j].id) {
            for (var k = 0; k < partJSON[i].categories[j].parts.length; k++) {
              var id = partJSON[i].categories[j].parts[k];
              var part = toolJS.arraySearch(this.partData, 'part_sku_id', id);
              showPartData.push(part);
            }
          }
        }
      }
      this.showPartData = showPartData;
    },
    updateSearchPartView() {
      var showPartData = [];
      for (var i = 0; i < partJSON.length; i++) {
        for (var j = 0; j < partJSON[i].categories.length; j++) {
          for (var k = 0; k < partJSON[i].categories[j].parts.length; k++) {
            var id = partJSON[i].categories[j].parts[k];
            var part = toolJS.arraySearch(this.partData, 'part_sku_id', id);
            if (part.id.indexOf(this.searchData.search) >= 0) {
              showPartData.push(part);
            }
          }
        }
      }
      this.showPartData = showPartData;
    },

    ImageClick(id) {
      if (this.searchData.search)
        return;
      this.searchData.search = id;
      this.updatePartView();
    },
    Searchorder() {
      console.log(this.searchData.search);
      if (!this.searchData.search) {
        this.updateCategoryView();
        return;
      }
      this.updateSearchPartView();
    },
    onSearchFocus() {
      this.searchData.search = '';
      this.selectedpartid = null;
    },
    PartChange(n, o, row) {
      console.log(n, o, row);
    },



    toOrder() {
      var that = this;
      this.orderSign = new Date().getTime();
      var ordervo = {};
      ordervo.maxpartcount = 9999; 
      ordervo.orderinfo = {};
      ordervo.orderinfo.ordertype = 2;
      ordervo.parts = [];
      this.partData.map(item => {
        if (item.number > 0) { 
          ordervo.parts.push({
            icon: item.icon,
            part_sku_id: item.part_sku_id,
            designcode: item.designcode,
            desc: item.desc, 
            number: item.number,
            qty: item.qty,
          });
        }
      })
      if (ordervo.parts.length <= 0) {
        Notification.error({
          title: this.$t('parts.noparts')
        });
        return;
      } 

      sessionStorage['__BUILDMOC_ORDER__' + this.orderSign] = JSON.stringify(ordervo);
      var router = { name: 'order', query: { sign: this.orderSign } };
      console.log(router);
      this.$router.push(router);
    },




    Selectcolor() {
      this.selectedpartcolor = this.select;
      this.refreshView();
    },
    emptyBag() {
      this.$confirm(this.$t('parts.deleteMessage'), '', {
        confirmButtonText: this.$t('parts.ok'),
        cancelButtonText: this.$t('parts.cancel'),
        type: 'warning'
      }).then(() => {
        this.partData.map(item => {
          item.number = 0
        })
      })
    }
  }
}